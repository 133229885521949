import CryptoJS from "crypto-js";
import moment from "moment";
import { APP_UPLOAD_FILE_MIME_TYPES } from "@/shared/constants/AppConst";
import { GENDERS } from "@/shared/constants/AppEnums";
import UserInfoItem from "@tinhaynhadat/core/AppViewItems/UserActionInfoItem/UserInfoItem";
import { FILE_TYPE, IMAGE_SUBTYPE, IMAGE_TYPE, UPLOAD_TYPE } from "@/constants";

export const checkPermissionGranted = ({ permissions = [], scopes = [] }) => {
  return permissions.some(
    (permission) =>
      !scopes || scopes.length === 0 || scopes.includes(permission)
  );
};

export const filterPermissionGrantedList = ({
  permissions = [],
  listItems = [],
}) => {
  return listItems.filter((item) =>
    checkPermissionGranted({ permissions, scopes: item.scopes })
  );
};

export const convertToS3Link = ({
  type,
  objectId,
  filename,
  fileType = FILE_TYPE.IMAGE,
}) => {
  return `${process.env.REACT_APP_LINK_S3}/${UPLOAD_TYPE[fileType]}/${type}/${objectId}/${filename}`;
};

export const reloadImg = async (url) => {
  await fetch(url, { cache: "reload", mode: "no-cors" });
  document.body
    .querySelectorAll(`img[src='${url}']`)
    .forEach((img) => (img.src = url));
};

export const convertDateTime = (timeStampFormat) => {
  return timeStampFormat && moment(timeStampFormat).format("DD/MM/YYYY h:mmA");
};

export const convertDate = (timeStampFormat) => {
  return timeStampFormat && moment(timeStampFormat).format("DD/MM/YYYY");
};

export const convertGender = (number) => {
  return GENDERS.find((gender) => gender.id === number)?.key;
};

export const formatDate = (date) => {
  let dateMoment;
  try {
    dateMoment = moment(date);
  } catch (e) {
    dateMoment = moment();
  }

  return dateMoment.subtract(1, "days").format();
};

export const formatTime = (time) => {
  let timeMoment;
  try {
    timeMoment = moment(time);
  } catch (e) {
    timeMoment = moment();
  }

  return timeMoment.subtract(1, "days").format("H:MM");
};

export const testImage = (url) => {
  return new Promise((resolve, reject) => {
    const tester = document.createElement("img");
    tester.addEventListener("error", () => reject("error"));
    tester.addEventListener("load", () => resolve("load"));
    tester.src = url;
  });
};

export const getEditedSingleImageInfo = (value) => {
  return {
    isEdited: value && typeof value === "object",
    isDeleted: !value,
  };
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const convertDollarString = (value) =>
  Number(value).toLocaleString("en-US", { style: "currency", currency: "USD" });

export const convertVNDString = (value) =>
  Number(value).toLocaleString("vi-VN", {
    style: "currency",
    currency: "VND",
  });

export const convertRawCategoriesToTreeDataFormat = ({
  categories = [],
  parentId = null,
}) =>
  categories
    .filter((item) => item.parentId === parentId)
    .map(({ name, id }) => ({
      title: name,
      value: id,
      children: convertRawCategoriesToTreeDataFormat({
        categories,
        parentId: id,
      }),
    }));

export const CryptoJsAES = {
  decode: (encrypted) =>
    CryptoJS.AES.decrypt(
      encrypted?.replaceAll("-", "/"),
      process.env.REACT_APP_CRYPTO_JS_SECRET ?? ""
    ).toString(CryptoJS.enc.Utf8),
  encode: (data) =>
    CryptoJS.AES.encrypt(data, process.env.REACT_APP_CRYPTO_JS_SECRET ?? "")
      .toString()
      ?.replaceAll("/", "-"),
};

export const filterByText = ({ value, input }) =>
  (value ?? "").toLowerCase().includes(input.toLowerCase());

export const getContentFromNamePath = ({ value, namePath }) => {
  if (!namePath) return null;
  else if (typeof namePath === "string") return value?.[namePath];
  else return namePath.reduce((prev, curr) => prev?.[curr], value);
};

export const getSelectUserCommonProps = ({ users = [] }) => {
  const options = users.map(
    ({ id, fullName, username, code, s3Profile, phoneNumber, email }) => {
      const name = fullName ? `${fullName ?? ""}` : username ?? "";
      return {
        label: (
          <UserInfoItem
            id={id}
            code={code}
            s3Profile={s3Profile}
            username={name}
            isSizeSmall={true}
            isShowContactInfo={true}
            phoneNumber={phoneNumber}
            email={email}
          />
        ),
        value: id,
        name,
        code,
        phoneNumber,
        email,
      };
    }
  );
  const filterOption = (input, option) =>
    filterByText({
      value: option?.name,
      input,
    }) ||
    filterByText({ value: option?.code, input }) ||
    filterByText({
      value: option?.phoneNumber,
      input,
    }) ||
    filterByText({ value: option?.email, input });
  return {
    options,
    filterOption,
    optionLabelProp: "name",
  };
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getFileInfoFromFileLink = (fileLink = "") => {
  const name = fileLink.split("_").pop();
  const fileExtension = name.split(".").pop();
  let type;
  switch (fileExtension) {
    case "docx":
    case "doc":
      type = APP_UPLOAD_FILE_MIME_TYPES.WORD[0];
      break;
    case "pdf":
      type = APP_UPLOAD_FILE_MIME_TYPES.PDF[0];
      break;
    case "xls":
    case "xlsx":
    case "ods":
      type = APP_UPLOAD_FILE_MIME_TYPES.EXCEL[0];
      break;
    default:
      type = APP_UPLOAD_FILE_MIME_TYPES.IMAGE[0];
  }
  return {
    name,
    type,
  };
};

export const getInsertAndDeleteIds = ({ oldList, newList }) => {
  const insertIds = newList?.filter((id) => !oldList?.includes(id));
  const deleteIds = oldList?.filter((id) => !newList?.includes(id));

  return {
    insertIds,
    deleteIds,
  };
};

export const getInsertAndDeleteImages = ({
  newThumbnail,
  oldThumbnail,
  oldImages,
  newImages,
  objectId,
}) => {
  let insertThumbnail;
  let deleteThumbnail;
  if (typeof newThumbnail === "object") {
    insertThumbnail = {
      s3Info: {
        filetype: FILE_TYPE.IMAGE,
        object_id: objectId,
        subtype: IMAGE_SUBTYPE.POST_THUMBNAIL,
        type: IMAGE_TYPE.POST,
      },
      file: newThumbnail,
    };

    const id = oldThumbnail?.split("/")?.pop();
    deleteThumbnail = { id, url: oldThumbnail };
  }

  const deleteImages = oldImages?.filter(
    (image) => !newImages?.find(({ id }) => id === image?.id)
  );

  const insertImages = newImages
    ?.map((image) => {
      if (!image?.id) {
        return {
          s3Info: {
            filetype: FILE_TYPE.IMAGE,
            object_id: objectId,
            subtype: IMAGE_SUBTYPE.POST_IMAGES,
            type: IMAGE_TYPE.POST,
          },
          file: image.originFileObj,
        };
      }

      return undefined;
    })
    ?.filter((image) => image);

  return {
    insertImages: [
      ...(insertThumbnail ? [insertThumbnail] : []),
      ...insertImages,
    ],
    deleteImages: [
      ...(deleteThumbnail ? [deleteThumbnail] : []),
      ...deleteImages,
    ],
  };
};

export const convertTitleToSlug = (title) =>
  title
    ?.toLowerCase()
    .replace(/([-])\s+/g, "") // Loại bỏ khoảng trắng sau dấu "-"
    .replace(/\s+([-])/g, "") // Loại bỏ khoảng trắng trc dấu "-"
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D")
    .replace(/[?,:%'"“”\b`’‘&+()]/g, "")
    .replace(/[/.]/g, "-")
    .replace(/\s+/g, " ")
    .trim()
    .split(" ")
    .join("-");

export const convertBase64ToFile = (base64) => {
  const type = base64.split(";")[0].split("/")[1];
  const byteString = atob(base64.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: `image/${type}` });
  const file = new File([blob], `image.${type}`, { type: `image/${type}` });
  return file;
};

export const convertContentToText = (content, deleteSpaceInside = true) => {
  if (!content) return "";
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");

  return deleteSpaceInside
    ? doc
        ?.querySelector("body")
        ?.innerHTML?.replace(/<\/?[^>]+(>|$)/g, " ")
        ?.replace(/\s+/g, " ")
        ?.trim()
        ?.toString()
    : doc
        ?.querySelector("body")
        ?.innerHTML?.replace(/<\/?[^>]+(>|$)/g, " ")
        ?.trim()
        ?.toString();
};
