import React from "react";
import { PERMISSIONS } from "@/shared/constants/AppEnums";

const Tags = React.lazy(() => import("./Tags"));
const Categories = React.lazy(() => import("./Categories"));
const Posts = React.lazy(() => import("./Posts"));
const Banners = React.lazy(() => import("./Banners"));
const Users = React.lazy(() => import("./Users"));
const CategoryKpi = React.lazy(() => import("./CategoryKpi"));
const GroupKpi = React.lazy(() => import("./GroupKpi"));
const Kpi = React.lazy(() => import("./Kpi"));
const Source = React.lazy(() => import("./Sources"));
const EmailNewsletters = React.lazy(() => import("./EmailNewsletters"));
const Advertisements = React.lazy(() => import("./Advertisements"));

export const cmsConfig = [
  {
    path: "/cms/tags",
    element: <Tags />,
    scopes: [
      PERMISSIONS.CREATE_TAG,
      PERMISSIONS.EDIT_TAG,
      PERMISSIONS.DELETE_TAG,
    ],
  },
  {
    path: "/cms/categories",
    element: <Categories />,
    scopes: [
      PERMISSIONS.CREATE_CATEGORY,
      PERMISSIONS.EDIT_CATEGORY,
      PERMISSIONS.DELETE_CATEGORY,
    ],
  },
  {
    path: "/cms/posts",
    element: <Posts />,
    scopes: [
      PERMISSIONS.CREATE_POST,
      PERMISSIONS.EDIT_POST,
      PERMISSIONS.DELETE_POST,
      PERMISSIONS.MARK_POST,
    ],
  },
  {
    path: "/cms/banners",
    element: <Banners />,
    scopes: [
      PERMISSIONS.CREATE_BANNER,
      PERMISSIONS.EDIT_BANNER,
      PERMISSIONS.DELETE_BANNER,
    ],
  },
  {
    path: "/cms/users",
    element: <Users />,
    scopes: [
      PERMISSIONS.CREATE_USER,
      PERMISSIONS.EDIT_USER,
      PERMISSIONS.DELETE_USER,
      PERMISSIONS.UPDATE_PASSWORD,
    ],
  },
  {
    path: "/cms/category-kpi",
    element: <CategoryKpi />,
    scopes: [
      PERMISSIONS.CREATE_CATEGORY_KPI,
      PERMISSIONS.EDIT_CATEGORY_KPI,
      PERMISSIONS.DELETE_CATEGORY_KPI,
    ],
  },
  {
    path: "/cms/group-kpi",
    element: <GroupKpi />,
    scopes: [
      PERMISSIONS.CREATE_GROUP_KPI,
      PERMISSIONS.EDIT_GROUP_KPI,
      PERMISSIONS.DELETE_GROUP_KPI,
    ],
  },
  {
    path: "/cms/kpi",
    element: <Kpi />,
    scopes: [
      PERMISSIONS.CREATE_KPI,
      PERMISSIONS.EDIT_KPI,
      PERMISSIONS.DELETE_KPI,
    ],
  },
  {
    path: "/cms/sources",
    element: <Source />,
    scopes: [
      PERMISSIONS.CREATE_SOURCE,
      PERMISSIONS.EDIT_SOURCE,
      PERMISSIONS.DELETE_SOURCE,
    ],
  },
  {
    path: "/cms/email-newsletters",
    element: <EmailNewsletters />,
    scopes: [PERMISSIONS.DELETE_EMAIL_NEWSLETTER],
  },
  {
    path: "/cms/advertisements",
    element: <Advertisements />,
    scopes: [PERMISSIONS.DELETE_ADVERTISEMENT],
  },
];
