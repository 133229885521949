import IntlMessages from "@tinhaynhadat/utility/IntlMessages";
import UserActionInfoItem from "@tinhaynhadat/core/AppViewItems/UserActionInfoItem";
import {
  APP_LANGUAGES,
  ORDER_STATUS_ENUM,
  TIME_RANGE_FILER_ENUM,
} from "./AppEnums";
import moment from "moment";
import StarItem from "@tinhaynhadat/core/AppViewItems/StarItem";
import { convertContentToText } from "@/@tinhaynhadat/utility/Utils";

export const initialUrl = "/dashboards"; // this url will open after login
export const PAGE_DEFAULT = 1;
export const TAKE_DEFAULT = 10;

export const TABLE_ACTION_COLUMN_KEY = "action";

export const UserActionColumns = [
  {
    title: <IntlMessages id="common.createBy" />,
    key: "createdBy",
    width: 250,
    render: (_data, { createdAt, createdBy }) => {
      const { id, email, s3Profile } = createdBy ?? {};
      return (
        <UserActionInfoItem
          id={id}
          s3Profile={s3Profile}
          time={createdAt}
          username={email}
        />
      );
    },
  },
  {
    title: <IntlMessages id="common.updateBy" />,
    key: "updatedBy",
    width: 250,
    render: (_data, { updatedAt, updatedBy }) => {
      if (!updatedBy) return null;
      const { id, email, s3Profile } = updatedBy;
      return (
        <UserActionInfoItem
          id={id}
          s3Profile={s3Profile}
          time={updatedAt}
          username={email}
        />
      );
    },
  },
];

export const ORDER_STATUS = [
  {
    value: ORDER_STATUS_ENUM.PAYMENT_FAIL,
    color: "orange",
    nextValue: [ORDER_STATUS_ENUM.CREATED],
  },
  {
    value: ORDER_STATUS_ENUM.WAITING_FOR_QUOTE,
    color: "orange",
    nextValue: [ORDER_STATUS_ENUM.CREATED],
  },
  {
    value: ORDER_STATUS_ENUM.CREATED,
    color: "processing",
    nextValue: [
      ORDER_STATUS_ENUM.FULL_PAYMENT,
      ORDER_STATUS_ENUM.WAITING_DEPOSIT,
    ],
  },
  {
    value: ORDER_STATUS_ENUM.FULL_PAYMENT,
    color: "cyan",
    nextValue: [ORDER_STATUS_ENUM.COMPLETED],
  },
  {
    value: ORDER_STATUS_ENUM.WAITING_DEPOSIT,
    color: "magenta",
    nextValue: [ORDER_STATUS_ENUM.DEPOSITED],
  },
  {
    value: ORDER_STATUS_ENUM.DEPOSITED,
    color: "volcano",
    nextValue: [ORDER_STATUS_ENUM.FULL_PAYMENT],
  },
  {
    value: ORDER_STATUS_ENUM.CANCELED,
    color: "red",
    nextValue: [],
  },
  {
    value: ORDER_STATUS_ENUM.COMPLETED,
    color: "success",
    nextValue: [],
  },
];

export const TIME_RANGE_FILTER = new Map([
  [
    TIME_RANGE_FILER_ENUM.THIS_WEEK,
    {
      label: "This week",
      value: {
        startDate: moment().startOf("week"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_WEEK,
    {
      label: "Last week",
      value: {
        startDate: moment().subtract(1, "week").startOf("week"),
        endDate: moment().subtract(1, "week").endOf("week"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.THIS_MONTH,
    {
      label: "This month",
      value: {
        startDate: moment().startOf("month"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_MONTH,
    {
      label: "Last month",
      value: {
        startDate: moment().subtract(1, "month").startOf("month"),
        endDate: moment().subtract(1, "month").endOf("month"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.THIS_YEAR,
    {
      label: "This year",
      value: {
        startDate: moment().startOf("year"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_YEAR,
    {
      label: "Last year",
      value: {
        startDate: moment().subtract(1, "year").startOf("year"),
        endDate: moment().subtract(1, "year").endOf("year"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.CUSTOM,
    {
      label: "Custom time range",
    },
  ],
]);

export const MONTHS_IN_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const BANNER_POSITION_OPTIONS = [
  {
    value: "HOME_RIGHT_1",
    label: "Home right 1",
  },
  {
    value: "HOME_RIGHT_2",
    label: "Home right 2",
  },
  {
    value: "HOME_RIGHT_3",
    label: "Home right 3",
  },
  {
    value: "SEARCH_TOP",
    label: "Search top",
  },
  {
    value: "SEARCH_BOTTOM_1",
    label: "Search bottom 1",
  },
  {
    value: "SEARCH_BOTTOM_2",
    label: "Search bottom 2",
  },
  {
    value: "SEARCH_BOTTOM_3",
    label: "Search bottom 3",
  },
  {
    value: "LARGE_CATEGORY_RIGHT_1",
    label: "Large category right 1",
  },
  {
    value: "LARGE_CATEGORY_RIGHT_2",
    label: "Large category right 2",
  },
  {
    value: "LARGE_CATEGORY_RIGHT_3",
    label: "Large category right 3",
  },
  {
    value: "LARGE_CATEGORY_RIGHT_4",
    label: "Large category right 4",
  },
  {
    value: "SMALL_CATEGORY_RIGHT_1",
    label: "Small category right 1",
  },
  {
    value: "SMALL_CATEGORY_RIGHT_2",
    label: "Small category right 2",
  },
  {
    value: "BEAUTI_CATEGORY_BOTTOM",
    label: "Beauti category bottom",
  },
  {
    value: "POST_DETAIL_RIGHT_1",
    label: "Post detail right 1",
  },
  {
    value: "POST_DETAIL_RIGHT_2",
    label: "Post detail right 2",
  },
  {
    value: "POST_DETAIL_BOTTOM",
    label: "Post detail bottom",
  },
];

export const STAR_FILTER = [
  { value: 5, label: <StarItem value={5} /> },
  { value: 4, label: <StarItem value={4} /> },
  { value: 3, label: <StarItem value={3} /> },
  { value: 2, label: <StarItem value={2} /> },
  { value: 1, label: <StarItem value={1} /> },
];

export const QUOTE_STATUS = [
  { value: "created", color: "processing" },
  { value: "done", color: "success" },
  { value: "cancel", color: "red" },
];

export const ROLE_MEMBER_NAME = "Member";

export const PASSWORD_RULES =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/;

export const USERNAME_RULE = /^[a-zA-Z0-9]{8,}$/;

export const SLUG_RULE = /^[a-zA-Z0-9-]+$/;

export const COMMON_FORM_ITEM_RULE = {
  REQUIRED: {
    required: true,
    message: "Bắt buộc",
  },
  TEXT_REQUIRED: () => ({
    required: true,
    validator(_, value) {
      return value.trim()
        ? Promise.resolve()
        : Promise.reject(new Error("Bắt buộc"));
    },
  }),
  EDITOR_REQUIRED: () => ({
    required: true,
    validator(_, value) {
      return convertContentToText(value)
        ? Promise.resolve()
        : Promise.reject(new Error("Bắt buộc"));
    },
  }),
  PASSWORD: {
    pattern: PASSWORD_RULES,
    message:
      "Mật khẩu phải chứa ít nhất 8 ký tự với ít nhất một ký tự hoa, một ký tự thường, một số và một ký tự đặc biệt",
  },
  EMAIL: {
    type: "email",
    message: "Email không dúng định dạng",
  },
  RE_ENTER_PASSWORD: ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("Mật khẩu và mật khẩu nhập lại không khớp!")
      );
    },
  }),
  USERNAME: {
    pattern: USERNAME_RULE,
    message:
      "Tên người dùng phải chứa 8 ký tự trở lên, không có ký tự đặc biệt và khoảng trắng",
  },
  SLUG: {
    required: true,
    pattern: SLUG_RULE,
    message: "Slug không được chứa ký tự đặc biệt và khoảng trắng",
  },
};

export const NONE_DATA_VALUE = -1;

export const APP_UPLOAD_FILE_TYPE = {
  PDF: "PDF",
  IMAGE: "IMAGE",
  WORD: "WORD",
  EXCEL: "EXCEL",
};

// docs: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const APP_UPLOAD_FILE_MIME_TYPES = {
  PDF: ["application/pdf"],
  WORD: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
  IMAGE: [
    "image/jpeg",
    "image/avif",
    "image/bmp",
    "image/gif",
    "image/vnd.microsoft.icon",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
  ],
  EXCEL: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.oasis.opendocument.spreadsheet",
  ],
};

export const APP_LANGUAGES_OPTIONS = [
  {
    label: "Vi",
    value: APP_LANGUAGES.VI_VN,
  },
  {
    label: "En",
    value: APP_LANGUAGES.EN_US,
  },
];
