import { gql } from "@apollo/client";

const GET_AUTH_USER = gql`
  query GetAuthUser($id: bigint!) {
    usersByPk: users_by_pk(id: $id) {
      id
      fullName: full_name
      email
      phoneNumber: phone_number
      avatar {
        filename
        objectId: object_id
      }
      role {
        permissionsRoles: permissions_roles {
          permission {
            name
          }
        }
      }
      dob
      gender
    }
  }
`;

export default GET_AUTH_USER;
