import "./shared/styles/richbeauty.less";
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from "./@tinhaynhadat";
import { BrowserRouter } from "react-router-dom";
import JWTAuthProvider from "./@tinhaynhadat/services/auth/jwt-auth/JWTAuthProvider";
import { ApolloProvider } from "@apollo/client";
import {
  ApolloClientContextProvider,
  ApolloClientContext,
} from "@tinhaynhadat/services/auth/jwt-auth/ApolloClientContext";

const App = () => (
  <AppContextProvider>
    <ApolloClientContextProvider>
      <ApolloClientContext.Consumer>
        {([apolloClient]) => (
          <ApolloProvider client={apolloClient}>
            <AppThemeProvider>
              <AppLocaleProvider>
                <BrowserRouter>
                  <JWTAuthProvider>
                    <AuthRoutes>
                      <AppLayout />
                    </AuthRoutes>
                  </JWTAuthProvider>
                </BrowserRouter>
              </AppLocaleProvider>
            </AppThemeProvider>
          </ApolloProvider>
        )}
      </ApolloClientContext.Consumer>
    </ApolloClientContextProvider>
  </AppContextProvider>
);

export default App;
