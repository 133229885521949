import { convertToS3Link } from "@tinhaynhadat/utility/Utils";
import { IMAGE_TYPE_URL } from "@/constants";

export const getUserFromJwtAuth = (user) => {
  const permissions = user?.role?.permissionsRoles?.map(
    (permissionRole) => permissionRole?.permission?.name
  );
  const { objectId, filename } = user?.avatar?.[0] ?? {};
  const avatarLink =
    filename &&
    convertToS3Link({
      objectId,
      type: IMAGE_TYPE_URL.USER,
      filename,
    });
  return {
    ...user,
    permissions,
    avatarLink,
    avatarFilename: filename,
  };
};
