export const ORDER_BY_GRAPHQL_ENUM = {
  ASC: "asc",
  ASC_NULLS_FIRST: "asc_nulls_first",
  ASC_NULLS_LAST: "asc_nulls_last",
  DESC: "desc",
  DESC_NULLS_FIRST: "desc_nulls_first",
  DESC_NULLS_LAST: "desc_nulls_last",
};

export const FILE_TYPE = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  DOCUMENT: "DOCUMENT",
};

export const UPLOAD_TYPE = {
  IMAGE: "image",
  VIDEO: "video",
  DOCUMENT: "document",
};

export const IMAGE_TYPE_URL = {
  BANNER: "banner",
  POST: "post",
  USER: "user",
  SETTING: "setting",
};

export const IMAGE_TYPE = {
  BANNER: "BANNER",
  POST: "POST",
  USER: "USER",
  SETTING: "SETTING",
};

export const IMAGE_SUBTYPE = {
  POST_THUMBNAIL: "POST_THUMBNAIL",
  POST_IMAGES: "POST_IMAGES",
  POST_IMAGE_CONTENT: "POST_IMAGE_CONTENT",
  USER_AVATAR: "USER_AVATAR",
  BANNER_HOME_RIGHT_TOP: "BANNER_HOME_RIGHT_TOP",
  BANNER_HOME_RIGHT_MIDDLE: "BANNER_HOME_RIGHT_MIDDLE",
  BANNER_HOME_RIGHT_BOTTOM: "BANNER_HOME_RIGHT_BOTTOM",
  BANNER_CATEGORY_RIGHT_TOP: "BANNER_CATEGORY_RIGHT_TOP",
  BANNER_CATEGORY_RIGHT_MIDDLE: "BANNER_CATEGORY_RIGHT_MIDDLE",
  BANNER_POST_RIGHT_MIDDLE: "BANNER_POST_RIGHT_MIDDLE",
  BANNER_POST_RIGHT_TOP: "BANNER_POST_RIGHT_TOP",
  BANNER_POST_RIGHT_BOTTOM: "BANNER_POST_RIGHT_BOTTOM",
  BANNER_POST_MIDDLE: "BANNER_POST_MIDDLE",
  BANNER_POST_BOTTOM: "BANNER_POST_BOTTOM",
  SETTING_LOGO: "SETTING_LOGO",
  SETTING_FAVICON: "SETTING_FAVICON",
  SETTING_AD_QUOTE: "SETTING_AD_QUOTE",
  SETTING_DISPLAY_BANNER_QUOTE: "SETTING_DISPLAY_BANNER_QUOTE",
  SETTING_PR_POST_QUOTE: "SETTING_PR_POST_QUOTE",
};

export const ERROR_CONSTRAINT_VIOLATION_CODE = "constraint-violation";

export const LAYOUT_PAGE_ENUM = {
  NORMAL: "NORMAL",
  BEAUTIFUL_HOUSES: "BEAUTIFUL_HOUSES",
};
