import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { setAuthToken } from "./jwt-api";
import { message } from "antd";
import { Amplify, Auth } from "aws-amplify";
import useGetAuthUser from "@/hooks/apis/users/useGetAuthUser";
import { ApolloClientContext } from "@tinhaynhadat/services/auth/jwt-auth/ApolloClientContext";
import { createHttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
  },
});

const JWTAuthContext = createContext(null);
const JWTAuthActionsContext = createContext(null);

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const refreshSession = async ({ apolloClient }) => {
  const session = await Auth.currentSession();
  const refreshToken = await session.getRefreshToken();
  const authUser = await Auth.currentAuthenticatedUser();
  if (refreshToken?.getToken()) {
    await authUser.refreshSession(refreshToken, (refErr) => {
      if (refErr) {
        throw refErr;
      }
    });
  }
  const currentSession = await Auth.currentSession();
  const idToken = await currentSession.getIdToken();
  const jwtToken = idToken.getJwtToken();
  const apolloLink = createAuthApolloLink(jwtToken);
  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "invalid-jwt":
            refreshSession({ apolloClient });
            return forward(operation);
        }
      }
    }
  });
  apolloClient.setLink(from([errorLink, apolloLink]));
};

const createAuthApolloLink = (token) => {
  return createHttpLink({
    uri: process.env.REACT_APP_API_URL_GRAPHQL,
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
};
const JWTAuthAuthProvider = ({ children }) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });
  const [apolloClient] = useContext(ApolloClientContext);
  const [getUser] = useGetAuthUser();
  const initClients = useCallback(async () => {
    try {
      await refreshSession({ apolloClient });
      const authUser = await Auth.currentAuthenticatedUser();
      const hasuraPayload =
        authUser?.signInUserSession?.idToken?.payload?.[
          "https://hasura.io/jwt/claims"
        ];
      const userId = hasuraPayload
        ? JSON.parse(hasuraPayload)["x-hasura-user-id"]
        : null;
      const { data: { usersByPk: user } = {} } = await getUser({
        variables: {
          id: userId,
        },
      });
      if (user) {
        setJWTAuthData({
          user,
          isLoading: false,
          isAuthenticated: true,
        });
      } else {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        message.error("Incorrect username or password.");
      }
    } catch (error) {
      console.log({ error });
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
      });
    }
  }, [apolloClient, getUser]);

  useEffect(() => {
    initClients();
  }, [getUser, initClients]);

  const signInUser = async ({ username, password }) => {
    try {
      await Auth.signIn(username, password);
      await initClients();
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      message.error("Incorrect username or password.");
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      setAuthToken();
      setJWTAuthData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = async ({ oldPassword, newPassword }) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signInUser,
          logout,
          changePassword,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
