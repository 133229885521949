import { RiDashboardLine, RiOrganizationChart } from "react-icons/ri";
import { PERMISSIONS } from "@/shared/constants/AppEnums";
import { MdOutlineTopic, MdSource } from "react-icons/md";
import { MdPostAdd } from "react-icons/md";
import {
  AiOutlineMail,
  AiOutlinePicture,
  AiOutlineSetting,
  AiOutlineTags,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { AuditOutlined, EditOutlined, GroupOutlined } from "@ant-design/icons";

const routesConfig = [
  {
    id: "dashboards",
    messageId: "sidebar.dashboards",
    type: "group",
    children: [
      {
        id: "dashboards",
        title: "Dashboards",
        messageId: "sidebar.dashboards",
        icon: <RiDashboardLine />,
        path: "/dashboards",
      },
    ],
  },
  {
    id: "cms",
    title: "CMS",
    messageId: "sidebar.cms",
    type: "group",
    children: [
      {
        id: "tags",
        title: "Tags",
        messageId: "sidebar.cms.tags",
        icon: <AiOutlineTags />,
        path: "/cms/tags",
        scopes: [
          PERMISSIONS.CREATE_TAG,
          PERMISSIONS.EDIT_TAG,
          PERMISSIONS.DELETE_TAG,
        ],
      },
      {
        id: "categories",
        title: "Categories",
        messageId: "sidebar.cms.categories",
        icon: <MdOutlineTopic />,
        path: "/cms/categories",
        scopes: [
          PERMISSIONS.CREATE_CATEGORY,
          PERMISSIONS.EDIT_CATEGORY,
          PERMISSIONS.DELETE_CATEGORY,
        ],
      },
      {
        id: "banners",
        title: "Banners",
        messageId: "sidebar.cms.banners",
        icon: <AiOutlinePicture />,
        path: "/cms/banners",
        scopes: [
          PERMISSIONS.CREATE_BANNER,
          PERMISSIONS.EDIT_BANNER,
          PERMISSIONS.DELETE_BANNER,
        ],
      },
      {
        id: "posts",
        title: "Posts",
        messageId: "sidebar.cms.posts",
        icon: <MdPostAdd />,
        path: "/cms/posts",
        scopes: [
          PERMISSIONS.CREATE_POST,
          PERMISSIONS.EDIT_POST,
          PERMISSIONS.DELETE_POST,
          PERMISSIONS.MARK_POST,
        ],
      },
      {
        id: "sources",
        title: "Sources",
        messageId: "sidebar.cms.sources",
        icon: <MdSource />,
        path: "/cms/sources",
        scopes: [
          PERMISSIONS.CREATE_SOURCE,
          PERMISSIONS.EDIT_SOURCE,
          PERMISSIONS.DELETE_SOURCE,
        ],
      },
      {
        id: "category-kpi",
        title: "Category KPI",
        messageId: "sidebar.cms.categoryKpi",
        icon: <MdOutlineTopic />,
        path: "/cms/category-kpi",
        scopes: [
          PERMISSIONS.CREATE_CATEGORY_KPI,
          PERMISSIONS.EDIT_CATEGORY_KPI,
          PERMISSIONS.DELETE_CATEGORY_KPI,
        ],
      },
      {
        id: "group-kpi",
        title: "Group KPI",
        messageId: "sidebar.cms.groupKpi",
        icon: <GroupOutlined />,
        path: "/cms/group-kpi",
        scopes: [
          PERMISSIONS.CREATE_GROUP_KPI,
          PERMISSIONS.EDIT_GROUP_KPI,
          PERMISSIONS.DELETE_GROUP_KPI,
        ],
      },
      {
        id: "kpi",
        title: "KPI",
        messageId: "sidebar.cms.kpi",
        icon: <EditOutlined />,
        path: "/cms/kpi",
        scopes: [
          PERMISSIONS.CREATE_KPI,
          PERMISSIONS.EDIT_KPI,
          PERMISSIONS.DELETE_KPI,
        ],
      },
      {
        id: "users",
        title: "Users",
        messageId: "sidebar.cms.users",
        icon: <AiOutlineUserAdd />,
        path: "/cms/users",
        scopes: [
          PERMISSIONS.CREATE_USER,
          PERMISSIONS.EDIT_USER,
          PERMISSIONS.DELETE_USER,
          PERMISSIONS.UPDATE_PASSWORD,
        ],
      },
      {
        id: "email-newsletters",
        title: "Email newsletters",
        messageId: "sidebar.cms.emailNewsletter",
        icon: <AiOutlineMail />,
        path: "/cms/email-newsletters",
        scopes: [PERMISSIONS.DELETE_EMAIL_NEWSLETTER],
      },
      {
        id: "advertisements",
        title: "Advertisements",
        messageId: "sidebar.cms.advertisements",
        icon: <AuditOutlined />,
        path: "/cms/advertisements",
        scopes: [PERMISSIONS.DELETE_ADVERTISEMENT],
      },
    ],
  },
  {
    id: "system",
    messageId: "sidebar.systems",
    type: "group",
    children: [
      {
        id: "roles",
        title: "Roles",
        messageId: "sidebar.systems.roles",
        icon: <RiOrganizationChart />,
        path: "/systems/roles",
        scopes: [
          PERMISSIONS.CREATE_ROLE,
          PERMISSIONS.EDIT_ROLE,
          PERMISSIONS.DELETE_ROLE,
        ],
      },
      {
        id: "settings",
        title: "Settings",
        messageId: "sidebar.systems.settings",
        icon: <AiOutlineSetting />,
        path: "/systems/settings",
        scopes: [PERMISSIONS.EDIT_SETTING],
      },
    ],
  },
];
export default routesConfig;
