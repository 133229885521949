import React from "react";
import { dashboardConfig } from "./dashboard";
import { errorPagesConfigs } from "./errorPages";
import { authRouteConfig } from "./auth";
import { initialUrl } from "@/shared/constants/AppConst";
import { profileConfig } from "./profile";
import ComponentCore from "./ComponentCore/ComponentCore";
import Error403 from "./errorPages/Error403";
import Error404 from "./errorPages/Error404";
import { systemConfig } from "@/pages/system";
import { cmsConfig } from "@/pages/cms";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [...dashboardConfig, ...profileConfig, ...cmsConfig, ...systemConfig],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: "/component-core",
      element: <ComponentCore />,
    },
    {
      path: "*",
      exact: true,
      element: <Error404 />,
    },
  ]),
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
