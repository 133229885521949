import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./shared/styles/richbeauty.less";
import "./shared/styles/vendors/index.css";
// import { DevSupport } from "@react-buddy/ide-toolbox";
// import { ComponentPreviews, useInitial } from "./dev";

ReactDOM.render(
  // <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
  <App />,
  // </DevSupport>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
