import React from "react";
import "./index.style.less";
import PropTypes from "prop-types";
import UserInfo from "@tinhaynhadat/core/AppLayout/components/UserInfo";
import AppScrollbar from "@tinhaynhadat/core/AppScrollbar";
import clsx from "clsx";
import AppVerticalMenu from "@tinhaynhadat/core/AppLayout/components/AppVerticalNav";
import { useSidebarContext } from "@tinhaynhadat/utility/AppContextProvider/SidebarContextProvider";
import MainSidebar from "@tinhaynhadat/core/AppLayout/components/MainSidebar";

const AppSidebar = ({ isCollapsed }) => {
  const { isSidebarBgImage } = useSidebarContext();

  return (
    <MainSidebar
      className={clsx("mini-sidebar-toggle", {
        "mini-sidebar-toggle-img-background": isSidebarBgImage,
      })}
      collapsible
      breakpoint="xl"
      collapsedWidth="0"
      collapsed={isCollapsed}
    >
      <UserInfo hasColor />
      <AppScrollbar className="app-mini-sidebar-scrollbar" scrollToTop={false}>
        <AppVerticalMenu />
      </AppScrollbar>
    </MainSidebar>
  );
};

export default AppSidebar;

AppSidebar.propTypes = {
  isCollapsed: PropTypes.bool,
};
