import viMessages from "@/shared/localization/locales/vi_VN.json";
import viVN from "antd/lib/locale/vi_VN";

const viLang = {
  messages: {
    ...viMessages,
  },
  antLocale: viVN,
  locale: "vi-VN",
};
export default viLang;
