const languageData = [
  // {
  //   languageId: "english",
  //   locale: "en",
  //   name: "English",
  //   icon: "us",
  // },
  {
    languageId: "vietnamese",
    locale: "vi",
    name: "Vietnamese",
    icon: "vn",
  },
];
export default languageData;
