import enMessages from "@/shared/localization/locales/en_US.json";
import enUS from "antd/lib/locale/en_US";

const EnLang = {
  messages: {
    ...enMessages,
  },
  antLocale: enUS,
  locale: "en-US",
};
export default EnLang;
