import React from "react";
import "./index.style.less";
import PropTypes from "prop-types";
import { useSidebarContext } from "@tinhaynhadat/utility/AppContextProvider/SidebarContextProvider";

const AppLogo = ({ hasSidebarColor }) => {
  const { sidebarColorSet } = useSidebarContext();
  return (
    <div className="app-logo">
      {hasSidebarColor && sidebarColorSet.mode === "dark" ? (
        <img src="/assets/images/logo.png" alt="tin-hay-nha-dat-logo" />
      ) : (
        <img src="/assets/images/logo.png" alt="tin-hay-nha-dat-logo" />
      )}
    </div>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
